import React from "react";
import styled from "styled-components";
import logo from "./images/logo.png";
import f from "./images/futuristic-house.jpg";
import arq from "./images/arq.jpeg";
import edi from "./images/edi.jpg";
import ind from "./images/ind.jpg";
import axios from "axios";

import "./App.css";
import useSWR from "swr";
import endpoints from "./endpoints";

const fetcher = (url) =>
  axios
    .get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      auth: {
        username: "josepvidalvidal@gmail.com",
        password: "123456",
      },
    })
    .then((r) => r.data);

function App() {
  // const { data: clients } = useSWR(endpoints.clients, fetcher);
  // const { data: constructions } = useSWR(endpoints.construction, fetcher);
  return (
    <Container>
      <header>
        <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
          <a className="navbar-brand" href="#">
            <img src={logo} height="40" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <a className="nav-link" href="#nosotros">
                  Sobre nosotros
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#clientes">
                  Nuestros clientes
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#obras">
                  Las obras
                </a>
              </li>
            </ul>
            {/*<div className="form-inline mt-2 mt-md-0"></div>*/}
          </div>
        </nav>
      </header>
      <main role="main">
        <div id="myCarousel" className="carousel slide" data-ride="carousel">
          <ol className="carousel-indicators">
            <li data-target="#myCarousel" data-slide-to="0" className=""></li>
            <li
              data-target="#myCarousel"
              data-slide-to="1"
              className="active"
            ></li>
            <li data-target="#myCarousel" data-slide-to="2" className=""></li>
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="first-slide" src={f} alt="Second slide" />
            </div>
            {/*  <div className="carousel-item">*/}
            {/*    <img*/}
            {/*      className="second-slide"*/}
            {/*      src={f}*/}
            {/*      alt="Third slide"*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*  <div className="carousel-item">*/}
            {/*    <img*/}
            {/*      className="third-slide"*/}
            {/*      src={f}*/}
            {/*      alt="Third slide"*/}
            {/*    />*/}
            {/*  </div>*/}
          </div>
          <a
            className="carousel-control-prev"
            href="#myCarousel"
            role="button"
            data-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#myCarousel"
            role="button"
            data-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="sr-only">Next</span>
          </a>
        </div>
        <div className="container marketing" id="nosotros">
          <hr className="featurette-divider" />
          <div className="row">
            <div className="col-lg-4">
              <img
                className="rounded-circle mb-3"
                src={ind}
                alt="Generic placeholder image"
                width="140"
                height="140"
              />
              <h2 className="mb-3">Industrial</h2>
              <p>
                Trabajamos para algunas de las industrias mas importantes del
                sector.
              </p>
            </div>
            <div className="col-lg-4">
              <img
                className="rounded-circle mb-3"
                src={edi}
                alt="Generic placeholder image"
                width="140"
                height="140"
              />
              <h2 className="mb-3">Edificaciones</h2>
              <p>
                Nuestro exito se basa en los exigentes controles de calidad, asi
                como las en soluciones diferenciales.
              </p>
            </div>
            <div className="col-lg-4">
              <img
                className="rounded-circle mb-3"
                src={arq}
                alt="Generic placeholder image"
                width="140"
                height="140"
              />
              <h2 className="mb-3">Arquitectura</h2>
              <p>
                Disponemos de un equipo humano profesional para poder dar un
                servicio serio y eficaz a todos tus proyectos.
              </p>
            </div>
          </div>
        </div>
        <hr className="featurette-divider" id="clientes" />
        <footer className="container">
          <div className="float-right">
            <p>
              <a href="https://www.instagram.com/adolfoconstructors/">
                Instagram
              </a>{" "}
              |{" "}
              <a href="https://es-es.facebook.com/adolfoconstructor/">
                Facebook
              </a>{" "}
              |{" "}
              <a href="https://www.linkedin.com/company/adolfo-constructors/about/">
                Linkedin
              </a>
            </p>
            <p className="float-right">
              <a href="#">Volver arriba</a>
            </p>
          </div>
          <div>
            <p>info@adolfoconstructors.com</p>
            <p>© 2020 Adolfo Constructors, Inc.</p>
          </div>
        </footer>
      </main>
    </Container>
  );
}

const Container = styled.div``;

const ClientsContainer = styled.header`
  h2 {
    margin-bottom: 50px;
    text-align: center;
  }
  .images {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    img {
      width: 100px;
    }
  }
`;

export default App;
